import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// constants
import { buttonLabels } from './constants';

// component
import LoginExpandingList from './partials/loginExpandingList';
import LoginMenuButton from './partials/loginMenuButton';

// actions
import { logoutUser } from '@/store/actions';

// utils
import useUserModal from '@/utils/hooks/use-login-modal';
import redirectToPlatform from '@/utils/redirect-to-platform';
import { getStudentInfoFromToken } from '@/utils/tokenParser';

// material
import { Button, ListItem, ListItemText, Typography } from '@mui/material';

// style
import useStyles from './style';

export default function LoginProfileButton(props) {
	const { classes } = useStyles();
	const dispatch = useDispatch();
	const isMobile = useSelector((state) => state.common.isMobile);
	const user = useSelector((state) => state.user.data);
	const openLoginModal = useUserModal();

	const onClickLogin = () => {
		openLoginModal('login');
	};

	const onHandleNavigation = () => {
		redirectToPlatform(getStudentInfoFromToken());
	};

	const data = React.useMemo(() => {
		if ('id' in user) {
			return {
				buttonLabel: buttonLabels.logedInLabel,
				userName:
					user && user.name
						? user.name
						: user.first_name[0].toUpperCase() + user.first_name.slice(1),
				iconButtonLetter:
					user && user.name
						? user.name[0].toUpperCase()
						: user.first_name[0].toUpperCase(),
				profileMenu: [
					{
						label: buttonLabels.redirectButtonLabel,
						onClick: () => onHandleNavigation(),
					},
					{
						label: buttonLabels.logoutButtonLabel,
						onClick: () => dispatch(logoutUser()),
					},
				],
			};
		}

		return {
			buttonLabel: buttonLabels.loggedOutLable,
			profileMenu: [],
			iconButtonLetter: '',
			userName: '',
		};
	}, [dispatch, user]);

	if (props.buttonType === 'listOption') {
		return 'id' in user ? (
			<LoginExpandingList
				label={data.buttonLabel}
				subOptions={data.profileMenu}
			/>
		) : (
			<ListItem
				button
				onClick={onClickLogin}
				className={classes.defaultMenuListColor}
			>
				<ListItemText primary={data.buttonLabel} />
			</ListItem>
		);
	}

	return 'id' in user ? (
		<LoginMenuButton
			subCategories={data.profileMenu}
			iconButtonLetter={data.iconButtonLetter}
			userName={data.userName}
		/>
	) : (
		<Button
			color="primary"
			variant={isMobile ? 'contained' : 'outlined'}
			className={classes.defaultButtonLabel}
			onClick={onClickLogin}
		>
			<Typography className={classes.loginButtonLabel}>
				{data.buttonLabel}
			</Typography>
		</Button>
	);
}

LoginProfileButton.propTypes = {
	styleType: PropTypes.string,
	buttonType: PropTypes.string.isRequired,
	analyticsProps: PropTypes.object,
};
